import React from "react";

import { Container, Icon, Button } from "semantic-ui-react";

import LayoutInner from "../components/layout/layout-inner";
import SEO from "../components/seo/seo";
import BodyContent from "../components/body-content/body-content";

import SecondaryNavBar from "../components/secondary-nav-bar/secondary-nav-bar";

const Page = () => (
  <LayoutInner>
    <SEO title="Digital Media Pack" keywords={[`rocksteady`]} />
    <SecondaryNavBar
      product="generic"
      title="Digital Media Pack"
      titlePath="/rocksteady-digital-media-pack-general/"
      titleActive={true}
      links={[]}
    />

    <Container>
      <BodyContent>
        <h1>Digital Media Pack</h1>
        <p>
          Download our digital media pack, to promote the benefits of Rocksteady
          in your school.
        </p>
        <Button
          as={"a"}
          href="/downloads/rocksteady-digital-media-pack-general.zip"
          primary
        >
          <Icon name="zip" size="big" /> Download
        </Button>
      </BodyContent>
    </Container>
  </LayoutInner>
);

export default Page;
